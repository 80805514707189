import React from 'react'
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

export const LDPEDana = () => {
  return (
    <>
    
{/* meta description and meta keyword start */}
<Helmet>
      {/* Title */}
      <title>HDPE Granules Manufacturer in Delhi | Vanshika Plastic</title>

{/* Meta Description */}
<meta name="description" content="Vanshika Plastic is a trusted HDPE granules manufacturer in Delhi, providing high-quality recycled and virgin HDPE granules for industrial use. Contact us for premium-grade plastic granules at competitive prices." />

{/* Meta Keywords */}
<meta name="keywords" content="HDPE granules manufacturer in Delhi, HDPE granules supplier in Delhi , recycled HDPE granules, virgin HDPE granules, HDPE plastic granules, HDPE raw material, HDPE resin, plastic granules manufacturers, HDPE granules for industrial use." />

{/* Robots Meta Tag */}
<meta name="robots" content="index, follow" />

{/* Open Graph Meta Tags */}
<meta property="og:title" content="HDPE Granules Manufacturer in Delhi | Vanshika Plastic" />
<meta property="og:description" content="Vanshika Plastic is a trusted HDPE granules manufacturer in Delhi, providing high-quality recycled and virgin HDPE granules for industrial use. Contact us for premium-grade plastic granules at competitive prices." />
<meta property="og:image" content="https://www.vanshikaplastic.com/categoriesimg/danaimg/3.png" />
<meta property="og:url" content="https://www.vanshikaplastic.com/LDPEDana" />
<meta property="og:type" content="website" />

{/* Twitter Card Meta Tags */}
<meta name="twitter:card" content="categoriesimg/danaimg/3.png" />
<meta name="twitter:title" content="HDPE Granules Manufacturer in Delhi | Vanshika Plastic" />
<meta name="twitter:description" content="Vanshika Plastic is a trusted HDPE granules manufacturer in Delhi, providing high-quality recycled and virgin HDPE granules for industrial use. Contact us for premium-grade plastic granules at competitive prices." />
<meta name="twitter:image" content="https://www.vanshikaplastic.com/categoriesimg/danaimg/3.png" />

{/* Canonical URL Tag */}
<link rel="canonical" href="https://www.vanshikaplastic.com/LDPEDana" />
      </Helmet>
{/* meta description and meta keyword end */}

      {/* Page title section start */}
      <section className="page_title_mkp_section">
        <div className="page_ti_overly_mkp_div">
          <div className="container">
            <div className="row">
              {/* Column start */}
              <div className="col-xxl-12 col-xl-12 col-lg-12 col-sm-12 col-12">
                <div className="page_title_tax_mkp_main_div">
                  <div className="page_title_tax_mkp_div">
                    <h1>LDPE Dana</h1>
                    <ul>
                      <li><Link to="/">Home</Link></li>
                      <li>LDPE Dana</li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* Column end */}
            </div>
          </div>
        </div>
      </section>
      {/* Page title section end */}

      {/* Product details section start */}
      <section className="products_dt_section">
        <div className="container">
          <div className="row justify-content-center">
            {/* Image section */}
            <div className="col-lg-4">
              <div className="product_details_img_box">
                <img src="../categoriesimg/danaimg/3.png"/>
              </div>
            </div>
            {/* Details section */}
            <div className="col-lg-7">
              <div className="product_dt_table">
                <h3>Specification</h3>
                <table className='table table-bordered table-striped'>
                  <tbody>

                    <tr>
                      <th>Name</th>
                     <td>LDPE Dana</td>
                    </tr>

                    <tr>
                        <th>Packaging</th>
                        <td>25 kg PP woven bags with inner liners</td>
                    </tr>

                    <tr>
                        <th>Recyclable</th>
                        <td>Yes</td>
                    </tr>

                  
                  </tbody>
                </table>
   
              </div>
              
          <div className="prodt_enqyury_btn">
              <a href="/contact-us">Enquiry Now</a>
          </div>
             
            </div>
          </div>
        </div>
      </section>
      {/* Product details section end */}
    </>
  )
};

export default LDPEDana;