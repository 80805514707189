import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { Categorydata } from '../data/Categorydata';
import { Helmet } from 'react-helmet';

const Hdpegranules = () => {
  const { id } = useParams(); // Extract the ID from the URL

  const filteredItems = Categorydata.filter(item => item.categoryname === 'HDPE Granules');
  return (
    <>

{/* meta description and meta keyword start */}
<Helmet>
        {/* Title */}
        <title>HDPE Granules Manufacturer in Delhi | Vanshika Plastic</title>

        {/* Meta Description */}
        <meta name="description" content="Vanshika Plastic is a trusted HDPE granules manufacturer in Delhi, providing high-quality recycled and virgin HDPE granules for industrial use. Contact us for premium-grade plastic granules at competitive prices." />

        {/* Meta Keywords */}
        <meta name="keywords" content="HDPE granules manufacturer in Delhi, HDPE granules supplier in Delhi , recycled HDPE granules, virgin HDPE granules, HDPE plastic granules, HDPE raw material, HDPE resin, plastic granules manufacturers, HDPE granules for industrial use." />

        {/* Robots Meta Tag */}
        <meta name="robots" content="index, follow" />

        {/* Open Graph Meta Tags */}
        <meta property="og:title" content="HDPE Granules Manufacturer in Delhi | Vanshika Plastic" />
        <meta property="og:description" content="Vanshika Plastic is a trusted HDPE granules manufacturer in Delhi, providing high-quality recycled and virgin HDPE granules for industrial use. Contact us for premium-grade plastic granules at competitive prices." />
        <meta property="og:image" content="https://www.vanshikaplastic.com/categoriesimg/hdpeimg/1.png" />
        <meta property="og:url" content="https://www.vanshikaplastic.com/hdpegranules" />
        <meta property="og:type" content="website" />

        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="categoriesimg/hdpeimg/1.png" />
        <meta name="twitter:title" content="HDPE Granules Manufacturer in Delhi | Vanshika Plastic" />
        <meta name="twitter:description" content="Vanshika Plastic is a trusted HDPE granules manufacturer in Delhi, providing high-quality recycled and virgin HDPE granules for industrial use. Contact us for premium-grade plastic granules at competitive prices." />
        <meta name="twitter:image" content="https://www.vanshikaplastic.com/categoriesimg/hdpeimg/1.png" />

        {/* Canonical URL Tag */}
        <link rel="canonical" href="https://www.vanshikaplastic.com/hdpegranules" />
        
      </Helmet>
{/* meta description and meta keyword end */}

      {/* pagte titile section start  */}
      <section className="page_title_mkp_section">
        <div className="page_ti_overly_mkp_div">
          <div className="container">
            <div className="row">
              {/* col start */}
              <div className="col-xxl-12 colxl-12 col-lg-12 col-sm-12 col-12">
                <div className="page_title_tax_mkp_main_div">
                  <div className="page_title_tax_mkp_div">
                    <h1> HDPE Granules</h1>
                    <ul>
                      <li><Link to="/">Home</Link></li>
                      <li>HDPE Granules</li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* col end */}
            </div>
          </div>
        </div>
      </section>
      {/* pagte titile section end */}

      {/* category page section start */}
      <section className="category_page_section">
        <div className="container">
          
            {filteredItems.map((item) => (
              <Categoryitems
              key={item.id} // Use a unique identifier as the key
              id={item.cateproduct_name.replace(/\s/g, '-')} // Pass the id directly from the item
              image={item.image}
              catdtpro_name={item.catdtpro_name}
              catdtpro_item={item.catdtpro_item}
              catdtpro_mfi={item.catdtpro_mfi}
              catdtpro_density={item.catdtpro_density}
              catdtpro_ash={item.catdtpro_ash}
              catdtpro_color={item.catdtpro_color}
              catdtpro_usages={item.catdtpro_usages}
              catdtpro_grade={item.catdtpro_grade}
              catdtpro_material={item.catdtpro_material}
              catdtpro_pacaging={item.catdtpro_pacaging}
              catdtpro_recycle={item.catdtpro_recycle}
              />
            ))}
          </div>
        
      </section>
      {/* category page section end */}
    </>
  );
};

export default Hdpegranules;

const Categoryitems = ({
  id,
  image,
  catdtpro_name,
  catdtpro_item,
  catdtpro_mfi,
  catdtpro_density,
  catdtpro_ash,
  catdtpro_color,
  catdtpro_usages,
  catdtpro_grade,
  catdtpro_material,
  catdtpro_pacaging,
  catdtpro_recycle
}) => {
  return (
    <>
    <div className="row">
      {/*  col start  */}
      <div className="col-lg-4 col-md-6 col-sm-12 col-12">
      <Link to={`/hdpegranules/${id}`}>
          <div className="category_items_main_div">
            <div className="category_items_img_box">
              <img src={image} alt="" className='img-fluid' />
            </div>
           
          </div>
          </Link>
      </div>
      {/*  col end */}

      {/*  col start  */}
      <div className="col-lg-8 col-md-6 col-sm-12 col-12">
      
      <div className="product_dt_table">
                <h3>Specification</h3>
                <table className='table table-bordered table-striped'>
                  <tbody>

                  {catdtpro_name && (
                      <tr>
                        <th>Name</th>
                        <td>{catdtpro_name}</td>
                      </tr>
                    )}

                {catdtpro_item && (
                    <tr>
                      <th>Item Code</th>
                      <td>{catdtpro_item}</td>
                    </tr>
                     )}


                     {catdtpro_mfi && (
                    <tr>
                      <th>MFI</th>
                       <td>{catdtpro_mfi}</td>
                    </tr>
                     )}

                     {catdtpro_density && (
                    <tr>
                      <th>Density</th>
                       <td>{catdtpro_density}</td>
                    </tr>
                     )}

                     {catdtpro_ash && (
                    <tr>
                      <th>Ash</th>
                       <td>{catdtpro_ash}</td>
                    </tr>
                     )}

                     {catdtpro_color && (
                    <tr>
                      <th>Color</th>
                       <td>{catdtpro_color}</td>
                    </tr>
                     )}

                     {catdtpro_usages && (
                    <tr>
                      <th>Usages</th>
                       <td>{catdtpro_usages}</td>
                    </tr>
                     )}


                     {catdtpro_grade && (
                    <tr>
                      <th>Grade</th>
                       <td>{catdtpro_grade}</td>
                    </tr>
                     )}


                     {catdtpro_material && (
                    <tr>
                      <th>Material</th>
                       <td>{catdtpro_material}</td>
                    </tr>
                     )}

                     {catdtpro_pacaging && (
                    <tr>
                      <th>Packaging</th>
                       <td>{catdtpro_pacaging}</td>
                    </tr>
                     )}

                     {catdtpro_recycle && (
                    <tr>
                      <th>Recyclable</th>
                       <td>{catdtpro_recycle}</td>
                    </tr>
                     )}

                     
                     <tr className='cate_eqbtn'>
                      <td colSpan={2}>
                        <div><a href="/contact-us">Enquiry Now</a></div>
                        </td>
                     </tr>

                  

                  </tbody>
                </table>
   
              </div>

              
        
      </div>
      {/*  col end */}
      </div>

    </>
  );
};