import React from 'react'
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

export const Hdpedana = () => {
  return (
    <>
    
{/* meta description and meta keyword start */}
<Helmet>
<title>Vanshika Plastic</title>
        {/* Meta Description for SEO */}
        <meta name="description" content="" />
        {/* Meta Keywords for SEO */}
        <meta name="keywords" content="" />
        {/* Robots Meta Tag: Tells search engines whether to index and follow the page */}
        <meta name="robots" content="index, follow" />
        {/* Open Graph (OG) Meta Tags for Social Media Sharing */}
        <meta property="og:title" content="" />
        <meta property="og:description" content="" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="" />
        <meta property="og:type" content="" />

        {/* Twitter Card Meta Tags for Twitter Sharing */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="" />
        <meta name="twitter:description" content="" />
        <meta name="twitter:image" content="" />

        {/* Canonical URL Tag to Avoid Duplicate Content Issues */}
        <link rel="canonical" href="" />
      </Helmet>
{/* meta description and meta keyword end */}

      {/* Page title section start */}
      <section className="page_title_mkp_section">
        <div className="page_ti_overly_mkp_div">
          <div className="container">
            <div className="row">
              {/* Column start */}
              <div className="col-xxl-12 col-xl-12 col-lg-12 col-sm-12 col-12">
                <div className="page_title_tax_mkp_main_div">
                  <div className="page_title_tax_mkp_div">
                    <h1>Hdpe dana</h1>
                    <ul>
                      <li><Link to="/">Home</Link></li>
                      <li>Hdpe dana</li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* Column end */}
            </div>
          </div>
        </div>
      </section>
      {/* Page title section end */}
     
      {/* Product details section start */}
      <section className="products_dt_section">
        <div className="container">
          <div className="row justify-content-center">
            {/* Image section */}
            <div className="col-lg-4">
              <div className="product_details_img_box">
                <img src="../categoriesimg/danaimg/1.png"/>
              </div>
            </div>
            {/* Details section */}
            <div className="col-lg-7">
              <div className="product_dt_table">
                <h3>Specification</h3>
                <table className='table table-bordered table-striped'>
                  <tbody>

                    <tr>
                      <th>Name</th>
                     <td>Hdpe dana</td>
                    </tr>

                    <tr>
                        <th>Packaging</th>
                        <td>25 kg woven PP bags with inner liners.</td>
                    </tr>

                    <tr>
                        <th>Recyclable</th>
                        <td>Yes</td>
                    </tr>

                  

                  </tbody>
                </table>
   
              </div>
              
<div className="prodt_enqyury_btn">
    <a href="/contact-us">Enquiry Now</a>
</div>
             
            </div>
          </div>
        </div>
      </section>
      {/* Product details section end */}
    </>
  )
};

export default Hdpedana;

