
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle.js';
import Header from './components/Header';
import './pages/style.css';
import './pages/responsive.css';
import Footer from './components/Footer';
import Home from './pages/Home';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Aboutus from './pages/Aboutus';
import Contactus from './pages/Contactus';
import ScrollToTop from './components/Scrolltop';
import Eprservices from './pages/Eprservices';
import Hdpegranules from './pages/Hdpegranules';
import LLdpegranules from './pages/LLdpegranules';
import PPCPgranules from './pages/PPCPgranules';
import Hdpegranulesdetails from './pages/Hdpegranulesdetails';
import LLdpegranulesdetails from './pages/LLdpegranulesdetails';
import PPCPgranulesdetails from './pages/PPCPgranulesdetails';
import Customcompounding from './pages/Customcompounding';
import { Hdpedana } from './pages/Hdpedana';
import Ldpegranules from './pages/Ldpegranules';
import LDPEDana from './pages/LDPEDana';
import Lldpedana from './pages/Lldpedana';
import PPDana from './pages/PPDana';
import PPGranules from './pages/PPGranules';
import PPCPDana from './pages/PPCPDana';






function App() {
  return (
    <>
  <BrowserRouter>
  <Header/>
  <Routes>
    <Route path="/" Component={Home}/>
    <Route path="/about-us" Component={Aboutus}/>
    <Route path="/contact-us" Component={Contactus}/>
    <Route path="/epr-services" Component={Eprservices}/>
    <Route path="/hdpe-granules" Component={Hdpegranules}/>
    <Route path="/hdpe-granules/:id" Component={Hdpegranulesdetails}/>
    <Route path="/lldpe-granules" Component={LLdpegranules}/>
    <Route path="/lldpe-granules/:id" Component={LLdpegranulesdetails}/>
    <Route path="/ppcp-granules" Component={PPCPgranules}/>
    <Route path="/ppcp-granules/:id" Component={PPCPgranulesdetails}/>
    <Route path="/custom-compounding" Component={Customcompounding}/>
    <Route path="/hdpe-dana" Component={Hdpedana}/>
    <Route path="/ldpe-granules" Component={Ldpegranules}/>
    <Route path="/ldpe-dana" Component={LDPEDana}/>
    <Route path="/lldpe-dana" Component={Lldpedana}/>
    <Route path="/pp-dana" Component={PPDana}/>
    <Route path="/pp-granules" Component={PPGranules}/>
    <Route path="/ppcp-dana" Component={PPCPDana}/>

  </Routes>
  <Footer/>
  <ScrollToTop/>
  </BrowserRouter>
  
    </>
  );
}


export default App;
