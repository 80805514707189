import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { Categorydata } from '../data/Categorydata';
import { Helmet } from 'react-helmet';

const Hdpegranulesdetails = () => {
  const { id } = useParams(); // Get the ID from the URL
  const product = Categorydata.find(item => item.cateproduct_name.replace(/\s/g, '-') === id);

  if (!product) {
    return <div>Product not found</div>;
  }

  // Replace spaces with hyphens in the product name
  const productNameWithHyphens = product.cateproduct_name.replace(/\s/g, '-');

  return (
    <>


{/* meta description and meta keyword start */}
<Helmet>
<title>Vanshika Plastic</title>
        {/* Meta Description for SEO */}
        <meta name="description" content="" />

        {/* Meta Keywords for SEO */}
        <meta name="keywords" content="" />

        {/* Robots Meta Tag: Tells search engines whether to index and follow the page */}
        <meta name="robots" content="index, follow" />

        {/* Open Graph (OG) Meta Tags for Social Media Sharing */}
        <meta property="og:title" content="" />
        <meta property="og:description" content="" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="" />
        <meta property="og:type" content="" />

        {/* Twitter Card Meta Tags for Twitter Sharing */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="" />
        <meta name="twitter:description" content="" />
        <meta name="twitter:image" content="" />

        {/* Canonical URL Tag to Avoid Duplicate Content Issues */}
        <link rel="canonical" href="" />
      </Helmet>
{/* meta description and meta keyword end */}

      {/* Page title section start */}
      <section className="page_title_mkp_section">
        <div className="page_ti_overly_mkp_div">
          <div className="container">
            <div className="row">
              {/* Column start */}
              <div className="col-xxl-12 col-xl-12 col-lg-12 col-sm-12 col-12">
                <div className="page_title_tax_mkp_main_div">
                  <div className="page_title_tax_mkp_div">
                    <h1>{product.cateproduct_name}</h1>
                    <ul>
                      <li><Link to="/">Home</Link></li>
                      <li>{product.cateproduct_name}</li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* Column end */}
            </div>
          </div>
        </div>
      </section>
      {/* Page title section end */}

      {/* Product details section start */}
      <section className="products_dt_section">
        <div className="container">
          <div className="row justify-content-center">
            {/* Image section */}
            <div className="col-lg-4">
              <div className="product_details_img_box">
                <img src={product.image} alt={product.cateproduct_name} />
              </div>
            </div>
            {/* Details section */}
            <div className="col-lg-8">
              <div className="product_dt_table">
                <h3>Specification</h3>
                <table className='table table-bordered table-striped'>
                  <tbody>

                

                  {product.catdtpro_name && (
                      <tr>
                        <th>Name</th>
                        <td>{product.catdtpro_name}</td>
                      </tr>
                    )}


                {product.catdtpro_item && (
                    <tr>
                      <th>Item Code</th>
                      <td>{product.catdtpro_item}</td>
                    </tr>
                     )}



                     {product.catdtpro_mfi && (
                    <tr>
                      <th>MFI</th>
                       <td>{product.catdtpro_mfi}</td>
                    </tr>
                     )}

                     {product.catdtpro_density && (
                    <tr>
                      <th>Density</th>
                       <td>{product.catdtpro_density}</td>
                    </tr>
                     )}

                     {product.catdtpro_ash && (
                    <tr>
                      <th>Ash</th>
                       <td>{product.catdtpro_ash}</td>
                    </tr>
                     )}

                     {product.catdtpro_color && (
                    <tr>
                      <th>Color</th>
                       <td>{product.catdtpro_color}</td>
                    </tr>
                     )}

                     {product.catdtpro_usages && (
                    <tr>
                      <th>Usages</th>
                       <td>{product.catdtpro_usages}</td>
                    </tr>
                     )}


                     {product.catdtpro_grade && (
                    <tr>
                      <th>Grade</th>
                       <td>{product.catdtpro_grade}</td>
                    </tr>
                     )}


                     {product.catdtpro_material && (
                    <tr>
                      <th>Material</th>
                       <td>{product.catdtpro_material}</td>
                    </tr>
                     )}

                     {product.catdtpro_pacaging && (
                    <tr>
                      <th>Packaging</th>
                       <td>{product.catdtpro_pacaging}</td>
                    </tr>
                     )}

                     {product.catdtpro_recycle && (
                    <tr>
                      <th>Recyclable</th>
                       <td>{product.catdtpro_recycle}</td>
                    </tr>
                     )}

                  

                  </tbody>
                </table>
   
              </div>
              
<div className="prodt_enqyury_btn">
    <a href="/contact-us">Enquiry Now</a>
</div>
             
            </div>
          </div>
        </div>
      </section>
      {/* Product details section end */}
    </>
  );
};

export default Hdpegranulesdetails;